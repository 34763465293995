<template>
  <div id="add-services">
    <v-container>
      <h1 class="mt-4">Add Subcategory</h1>
      <ServicesForm
        :initial-service="service"
        @save="createNewService"
      />
      <Alert
        :showAlert="saveClicked.isClicked"
        :type="saveClicked.type"
        allowDismiss
        absolute
        :text="saveClicked.message"
        :inPage="false"
        :backgroundColour="true"
      />
    </v-container>
  </div>
</template>
<script>
import { Alert } from '@nswdoe/doe-ui-core'
import ServicesForm from '@/views/Services/form'
import { DEFAULT_SERVICE } from '@/constants/initial'
import { cloneDeep } from 'lodash'

export default {
  name: 'AddSubcategory',
  title: 'Add Subcategory - SAIS Operation UI Portal',
  components: {
    Alert,
    ServicesForm,
  },
  data() {
    return {
      service: cloneDeep(DEFAULT_SERVICE),
      saveClicked: {
        isClicked: false,
        isSuccess: false,
        message: '',
      },
    }
  },
  methods: {
    async createNewService(item) {
      if (item.allowedResources.length < 1) {
        this.saveClicked = {
          isClicked: true,
          type: 'error',
          isSuccess: false,
          message: 'Please select at least one SIF Object',
        }
        return
      }
      const response = await this.$store.dispatch('moduleServices/postService', item)
      this.saveClicked = {
        isClicked: true,
        type: response.isError ? 'error' : 'success',
        isSuccess: !response.isError,
        message: response.isError
          ? response.errorMessage
          : 'The changes have been saved successfully',
      }

      if (!response.isError) {
        this.$router.push('/serviceregistry/services?created=true')
      }
    },
  },
}
</script>

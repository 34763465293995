let DEFAULT_SUPPLIER = {
  supplierType: 'AMPS',
  supplierCode: '',
  name: '',
  website: '',
  emailDomains: [],
  status: 'Active',
  contacts: [
    {
      name: '',
      emailAddress: '',
      number: '',
      type: 'Contract',
    },
    {
      name: '',
      emailAddress: '',
      number: '',
      type: 'Technical',
    },
  ],
}

let DEFAULT_PRODUCT = {
  supplierName: '',
  productCode: '',
  name: '',
  state: 'OPEN_FOR_BUSINESS',
  isFinancial: false,
}

let DEFAULT_SERVICE = {
  roleId: '',
  description: '',
}

let DEFAULT_SERVICE_SUBSCRIPTION = {
  justification: {
    type: '',
    value: '',
  },
  isFinancial: false,
}

let DEFAULT_SERVICE_SUBSCRIPTION_REQUEST = {
  justification: {
    type: '',
    value: '',
  },
  isFinancial: false,
}

let DEFAULT_SUPPLIER_USER = {}

export {
  DEFAULT_SUPPLIER,
  DEFAULT_PRODUCT,
  DEFAULT_SERVICE,
  DEFAULT_SERVICE_SUBSCRIPTION,
  DEFAULT_SERVICE_SUBSCRIPTION_REQUEST,
  DEFAULT_SUPPLIER_USER,
}

<template>
  <v-form ref="servicesForm">
    <v-card class="pa-6 my-8">
      <AdsTextField
        v-model="service.id"
        label="ID"
        maxlength="50"
        :disabled="isView"
        :rules="[validators.required, validators.alphaNumericMuliHyphens]"
      />
      <AdsTextField
        v-model="service.description"
        label="description"
        maxlength="300"
        :rules="[validators.required]"
        :disabled="isReadOnlyUser"
      />
    </v-card>
    <v-card class="pa-6 my-8">
      <AdsSelect
        v-model="sifObject.accountId"
        :items="sifObjectsItems"
        class="mb-0"
        outlined
        label="Change SIF Object to"
        placeholder="Please select..."
        :disabled="isReadOnlyUser"
      />
      <AdsSelect
        v-model="sifObject.profile"
        :items="profileItems"
        class="mb-0"
        outlined
        label="Change profile to"
        placeholder="Please select..."
        :disabled="isReadOnlyUser"
      />
      <AdsButton
        :secondary="true"
        buttonText="Add SIF Object Mapping"
        class="mr-4"
        :disabled="isReadOnlyUser"
        @click="addSifObjectMapping"
      />
    </v-card>
    <v-card class="pa-6 my-8">
      <AdsDataTable
        :headers="headers"
        :items="items"
        disable-pagination="true"
        density="compact"
        search-label="Find products"
        sort-desc
      >
        <template v-slot:top />
        <template v-slot:footer />
        <template v-slot:bottom />
        <template v-slot:item.apiResources="{ item }">
          <div
            v-for="apiResource in item.apiResources"
            :key="apiResource"
          >
            {{ apiResource }}
          </div>
        </template>
        <template #item.actions="{ item }">
          <AdsButton
            :tertiary="true"
            icon="mdi-delete-outline"
            buttonText=""
            class="mr-4"
            :disabled="isReadOnlyUser"
            @click="deleteSifObject(item.accountId)"
          />
        </template>
      </AdsDataTable>
    </v-card>
    <AdsButton
      :primary="true"
      buttonText="Save"
      class="mr-4"
      @click="save"
      :disabled="isReadOnlyUser"
    />
    <AdsButton
      :primary="true"
      buttonText="Cancel"
      @click="cancel"
    />
  </v-form>
</template>
<script>
import { AdsTextField, AdsButton, AdsSelect, AdsDataTable } from '@nswdoe/doe-ui-core'
import { DEFAULT_SERVICE } from '@/constants/initial'
import { cloneDeep } from 'lodash'
import validators from '@/helpers/validators'
import { getFormErrors } from '@/helpers/form'

export default {
  name: 'AddServices',
  components: {
    AdsTextField,
    AdsButton,
    AdsSelect,
    AdsDataTable,
  },
  props: {
    initialService: {
      type: Object,
      default: cloneDeep(DEFAULT_SERVICE),
    },
    isView: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isAdmin() {
      return this.$store.state.isAdmin
    },
    isReadOnlyUser() {
      return this.$store.state.isReadOnlyUser
    },
  },
  data() {
    let selectedItems = []
    if (this.initialService.allowedResources) {
      selectedItems = this.initialService.allowedResources.map((item) => ({
        accountId: item.accountId,
        profile: item.fieldAccessTemplateId,
        apiName: item.apiName,
        apiResources: item.allowedActions,
      }))
    }

    return {
      service: this.initialService,
      sifObject: {
        accountId: '',
        profile: '',
        apiName: '',
        apiResources: [],
      },
      sifObjectsItems: [],
      profileItems: ['dataset-b', 'dataset-bplus', 'dataset-a'],
      apiResourcesItems: [],
      sifObjects: [],
      headers: [
        { text: 'SIF Objects', value: 'accountId' },
        { text: 'Profile', value: 'profile' },
        { text: 'API Resources', value: 'apiResources' },
        { text: 'Actions', value: 'actions' },
      ],
      items: selectedItems,
      errors: [],
      showErrors: false,
      validators: {
        required: validators.required,
        alphaNumericMuliHyphens: validators.alphaNumericMuliHyphens(),
        lowerCase: validators.lowerCase,
      },
    }
  },
  created() {
    this.$store.dispatch('moduleSifObjects/fetchSifObjects').finally(() => {
      this.sifObjects = this.$store.state.moduleSifObjects.sifObjects
      this.sifObjectsItems = this.sifObjects.map((item) => item.accountId)
    })
  },
  methods: {
    cancel() {
      this.$router.push('/serviceregistry/services')
    },
    save() {
      this.validate()
      this.errors = getFormErrors(this.$refs.servicesForm)

      if (this.errors && this.errors.length > 0) {
        this.showErrors = true
      } else {
        const allowedResources = this.items.map((item) => {
          return {
            accountId: item.accountId,
            apiName: item.apiName || this.findSifObjectByAccountId(item.accountId)?.description,
            fieldAccessTemplateId: item.profile,
            allowedActions: item.apiResources,
          }
        })
        this.service.allowedResources = allowedResources
        this.$emit('save', this.service)
      }
    },
    validate() {
      if (this.$refs.servicesForm) {
        this.$refs.servicesForm.validate()
      }
    },
    findSifObjectByAccountId(accountId) {
      return this.sifObjects.find((sif) => sif.accountId === accountId)
    },
    addSifObjectMapping() {
      if (!this.sifObject.accountId || !this.sifObject.profile) {
        return
      }

      const selectedSifObject = this.sifObjects.find(
        (sif) => sif.accountId === this.sifObject.accountId
      )

      let selectedApiResources = []

      if (this.sifObject.profile === 'dataset-a' || this.sifObject.profile === 'dataset-b') {
        selectedApiResources = selectedSifObject?.allowableActions.filter((action) =>
          action.endsWith('read')
        )
      } else {
        selectedApiResources = selectedSifObject?.allowableActions
      }

      this.sifObject.apiResources = [...selectedApiResources]
      this.sifObject.apiName = selectedSifObject?.description

      const isAlreadyAdded = this.items.some((item) => item.accountId === this.sifObject.accountId)

      if (!isAlreadyAdded) return this.items.push({ ...this.sifObject })

      // 3 types of profiles
      // dataset-a, dataset-b, dataset-bplus
      // dataset-bplus is the most permissive
      // dataset-a is the least permissive
      // dataset-b is the middle ground
      this.items = this.items.filter((item) => item.accountId !== this.sifObject.accountId)
      this.items.push({ ...this.sifObject })
    },
    deleteSifObject(accountId) {
      const idx = this.items.findIndex((item) => item.accountId === accountId)
      this.items.splice(idx, 1)
    },
  },
}
</script>
